import React,{useState,useEffect} from "react";
import NavBar from "../components/NavBar";
import styles from '../pagesCss/homepage.module.css';
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import ImageSlider from "../components/ImageSlider";
import Model from "../components/ContactModel";
import Alert from "../components/Alert";
import axios from 'axios'

const HomePage=()=>{
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const [productDetails,setProductDetails]=useState([   
        
        {
            "link":"/disposable_syringe",
            "category":"InfandTrans",
            "name":"Disposable Syringe",
            "img":"Inf_and_trans/Disposable Syringe-water.png",
            "details":"We are one of the renowned Manufacturers, Traders and Exporters of premium quality Disposable Syringe. It is used for injecting liquid injectable inside body. Under the instructions of team of medical and pharmaceutical expertise, it is processed by using the top grade quality medical grade polypropylene and advanced techniques in adherence to the industry standards and norms. Further, the Disposable Syringe available to us in different sizes and specifications at pocket-friendly rates."
        },
       
       {
            "link":"/IV_set",
            "category":"InfandTrans",
            "name":"IV Set",
            "img":"Inf_and_trans/IV Set-water.png",
            "details":"Details of IV Set Designed by using high quality raw material equipment Offered at market leading prices Suitable for lean individuals, such as kids and athletes .We believe in satisfying the customer with innovations and product services .We have been awarded with an ISO 9001:2008 certificate for offering best quality infusion sets.We highly believe in transparent as well as honest dealing."
        },
      
    ])

    const [alert,setAlert]=useState(false);

    const hideAlert=()=>setAlert(false)

    const [selectedProduct,setSelectedProduct]=useState("")


    // =========================  Form data
    const [formAlert,setFormAlert]=useState(false)
    const [message,setMessage]=useState("Please Fill All Requird Feilds Correctly !")
    const [processStage,setProcessStage]=useState("form")
    const [alertColor,setAlertColor]=useState("red") 
    const [formData,setFormData]=useState({
        name :"",
        email :"",
        phone_number :"",
        topic :"",
        message:""
       })
  
        const sendData=(event)=>{
        event.preventDefault();
        console.log(formData)
        
        setProcessStage("sending")
  
        if(formData.email =="" || formData.name =="" || formData.phone_number =="" || formData.topic==""){
          setMessage("Please Fill All Requird Feilds Correctly !")
          setFormAlert(true)
          setProcessStage("form")
          setTimeout(()=> setFormAlert(false), 5000);
          setAlertColor("red")
        }
        else if(validateEmail(formData.email)==false ){
            setAlertColor("red")
          setMessage("Please Enter Valid Email")
          setFormAlert(true)
          setProcessStage("form")
          setTimeout(()=> setFormAlert(false), 5000);
        }
        else if(validatePhone(formData.phone_number)==false){
            setAlertColor("red")
          setMessage("Please Enter 10 Digit Phone Number")
          setFormAlert(true)
          setProcessStage("form")
          setTimeout(()=> setFormAlert(false), 5000);
        }
        else{
            setAlertColor("blue")
            setMessage("Sending Query ..")
            setFormAlert(true)
            axios.post("https://raj74434.pythonanywhere.com/medi/inquery", 
            formData
          )
        .then(response => {
            setMessage("Sent Successfully")
            setAlertColor("green")
            console.log(response)
            setFormData({
                name :"",
                email :"",
                phone_number :"",
                topic :"",
                message:""
               })
            setTimeout(()=> setFormAlert(false), 6000);
            
              

        })
        .catch(error => {
            setAlertColor("red")
          setMessage("Please Contact Us On Phone Number or given email")
          setFormAlert(true)
          setProcessStage("form")
          setTimeout(()=> setFormAlert(false), 5000);
          console.error('Error:', error);
        });
      }
         
       }


    // =========================  Validate

    const validateEmail = (email) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
    
      const validatePhone = (phone) => {
        // Regular expression for basic phone number validation
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phone);
      };

    return(
        <div className={styles.homePageContainer} >
            <NavBar/>
            <div className={styles.contactLine}>
                    
                    <div>
                        <h3><Link className={styles.contactLineLinkTag}  to={"tel:9667065001"}>  <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                             <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                        </svg>  Call Us:  9667065001 </Link>  </h3>
                    </div>
                    <div style={{marginLeft:"10px"}}>
                        <h3> <Link className={styles.contactLineLinkTag} to={"mailto:mediglowmedicare.com"}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                         <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                         </svg> Email Us : info@mediglowmedicare.com  </Link></h3>
                    </div>
                </div>
            {/* <ImageSlider/>  */}
            <div style={{backgroundColor:"#c9d7f2"}}>
            <div className={styles.companyNameDoctor}>
                
                <div className={styles.companyName}>
                    <h1>MediGlow  Medicare</h1>
                    
                    <h4> Timely Delivery </h4>
                    <hr />
                    <h4>Quality Assurance</h4>
                    <hr/>
                    <h4>Customer Satisfaction</h4>
                    <hr/>
                    <h4>Long Term Partnership</h4>
                    <hr/>
                     
                </div>
                <div className={styles.doctorPhotoDiv}>
                    <img className={styles.doctor1} src={require("../images/doctor.jpg")} />
                </div>

               
                

            </div>
            </div>

            {alert && <Model hide={hideAlert} name={selectedProduct} />}
           
            <Alert props={formAlert} message={message} color={alertColor} />
            
            <div className={styles.body}>

             
              
               
                <div className={styles.popularProductList}>
                    <div style={{backgroundColor:"#00438b",color:"white",padding:"0.5px 15px"}}><h2>Popular Products</h2></div>
                    <div className={styles.popularProductListInner}>{productDetails.map((el,index)=>
                        <div className={styles.popularProductCards}>
                            <div className={styles.popularProductImg}> <img src={require('../images/productImg/'+el.img)}/></div>
                            <div><h3>{el.name}</h3></div>
                            <div className={styles.popularProductListButtons}>
                                <button onClick={()=>{setAlert(true); setSelectedProduct(el.name)}} className={styles.popularProductListInquery}>Inquiry</button>
                                <Link to={el.link}> <button className={styles.popularProductView}>View More</button></Link>
                            </div>
                        </div>
                        
                    )}
                    </div>
                   
                    <Link to="/category">
                        <button className={styles.popularProductViewMoreBtn}> 
                            <h3>View More Products</h3>
                        </button>
                    </Link>

                </div>
           </div>

           <div className={styles.aboutCompanyHomeBackground}>
                    <div className={styles.aboutCompanyHomeBlur}>
                        <div className={styles.aboutCompanyHome}>
                            <div  className={styles.aboutCompanyHomeHeading}>
                        <h1>Discover a new era of health with MediGlow ! </h1>
                        <hr/>
                        </div>
                       <div className={styles.aboutCompanyHomeDesc}>
                        <h3>  Mediglow Medicare Private Limited offers a
                             comprehensive range of medical disposable items 
                             <br/>
                             that meet the rigorous quality standards required 
                             by healthcare professionals and facilities.

                        </h3>
                        </div>
                        <Link to="/about"><button  className={styles.aboutCompanyHomeBTN}> <h3>Read More</h3></button> </Link>
                        </div>
                        
                    </div>
           </div>

           <div className={styles.body}>
              
                         

                <div className={styles.homePageContact}>
                    <div className={styles.homePageContactTagDiv}>
                        <h1> Unlocking Success:
                            <br/>
                         Let's Talk Business and Turn Ideas into Reality! </h1>
                    </div>
                    <div className={styles.homePageContactFormDiv}>

                    <form onSubmit={sendData} className={styles.contactForm}>
<input required  onChange={(event) => setFormData({ ...formData, name: event.target.value })}   type='text' placeholder='Name' name='name' value={formData.name}  ></input>
                        
<input required onChange={(event) => setFormData({ ...formData, email: event.target.value })} type='email' placeholder='Email' name='email' value={formData.email}></input>
                        <br/>
<input required onChange={(event) => setFormData({ ...formData, phone_number: event.target.value })}  type='number' placeholder='Phone Number' name='phone_number' value={formData.phone_number}></input>
<input required onChange={(event) => setFormData({ ...formData, topic: event.target.value })}  type='text' placeholder='Enter Topic' name='topic' value={formData.topic}></input>
                      
<textarea required onChange={(event) => setFormData({ ...formData, message: event.target.value })}  rows="4" cols="50" className={styles.message} placeholder="Enter your message here..." value={formData.message}></textarea>
                        <br/>
                        <button type='submit'   className={styles.contactbtn} > Send</button>
                    </form>
                    </div>
                </div>

                <div className={styles.contact}>
                    <div></div>
                </div>
              
                


            </div>

             <Footer/>

        </div>

    );

}

export default HomePage;