import React,{useState,useEffect} from "react"
import style from "../pagesCss/inf_and_trans.module.css"
import NavBar from "../components/NavBar.jsx"
import Footer from "../components/Footer"
import { Link } from "react-router-dom"
import Model from "../components/ContactModel.jsx"

const Gloves=()=>{

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const data=[
        {
             "link":"/disposable_latex_glove",
             "name":"Disposable Latex Glove",
             "img":"disposable_latex_glove.png",
             "des":"Disposable Latex Glove is a flexible and soft hand protective covering that is made up by using premium quality latex materials. This single use glove is commonly used by doctors and surgeons to prevent risk of disease transmission. It is in high demand within healthcare facilities, food service, and laboratory settings, among others. Disposable Latex Glove is highly elastic, which allows them to fit snugly and comfortably on the hand. It comes in various standard sizes as per the order placed by our customers. Buy from us latex gloves in bulk at a reasonable price."
            
         },
         {
             "link":"/examination_rubber_gloves",
             "name":"Examination Rubber Gloves",
             "img":"examination_rubber_gloves.png",
             "des":" This Examination Rubber Glove is designed with several features to provide maximum protection and comfort. It is made of high-quality, medical-grade materials that offer excellent strength and durability. It comes with a textured surface that provides an enhanced grip for secure handling. It is suitable for use by people with sensitive skin. This Examination Rubber Glove ensures a perfect fit for different hand sizes. It is also easy to wear, with a flexible design that offers excellent tactile sensitivity and dexterity, and it is ideal for delicate medical procedures."
         },
         {
             "link":"/latex_surgical_gloves",
             "name":"Latex Surgical Gloves",
             "img":"latex_surgical_gloves.png",
             "des":"Our company is highly appreciated as the best Trader, Exporter and Manufacturer of Latex Surgical Gloves. It is ideal for general surgery and widely used by medical professionals & doctors to avoid any kind of infection. These gloves are fabricated under the instructions of our team of well-experienced professionals, by utilizing the superior grade leather and modern techniques in compliance with the global market standards and norms. Moreover, we are supplying the Latex Surgical Gloves in different sizes at affordable prices."
         }
    ]

    const [alert,setAlert]=useState(false);

    const hideAlert=()=>setAlert(false)

    const [selectedProduct,setSelectedProduct]=useState("")


    return(

        <div>
        <NavBar/>
        <div className={style.body}>
        {alert && <Model hide={hideAlert} name={selectedProduct} />}
            {/* <img src={require(i)}/> */}
        
            {
                data.map((el,ind)=>(
                <div>
                    
               <div key={ind} className={style.product}  >
                   
               <div className={style.imgdiv}>
                        
               <Link to={el.link}>   <img className={style.imgdivImg} src={require('../images/productImg/gloves/'+el.img)}/> </Link>
                    </div> 

                    <div className={style.right}>
                    <Link to={el.link} className={style.rightProductNameLinkTag}> <div><h2>{el["name"] } </h2></div> </Link>
                    <button className={style.rightContactBtn} onClick={()=>{setAlert(true); setSelectedProduct(el.name)}} > Contact Us</button>
                    </div>
                   
                </div>
                <hr/>
                </div>  
                ))
            }

        </div>

        <Footer/>
    </div>

    )

}

export default Gloves;