import React, { useState,useEffect } from "react"
import styless from "../pagesCss/categorypage.module.css"
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import {Link} from "react-router-dom"
import { Button } from "bootstrap";



const CategoryPage=()=>{

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

const[category,setCategory]=useState({
    "Disposable Cap":{
        "products":{
            1:"Disposable Cap",
        },
        "img":"disposable_cap.jpg",
        "details":"By keeping in mind the hygienic work and safety of our clients, we are manufacturing and exporting a huge collection of Disposable Cap. It is widely used for securing the patient from dropping of hair or dandruff while performing the treatments and operations. Demanded in various pharmaceutical, food processing, laboratories, operation theaters, hospitals, etc. Under the supervision of our experienced professionals, it is fabricated by using the non woven fabric and contemporary machinery as per the industry norms. Furthermore, we are offering the skin-friendly Disposable Cap in various sizes at affordable prices."
    },

    "Disposable Face Mask":{
        "products":{
            1:"Disposable Face Mask",
        },
        "img":"face_mask.png",
        // "img":"https://flents.com/cdn/shop/articles/three-disposable-face-masks_3350x_fcbd4ed6-5d67-4170-89c6-13f6dafcd1c0_1728x.jpg?v=1604631248",
        "details":"To meet the diverse requirements of our leading clients, we are manufacturing and exporting a wide range of Disposable Face Mask. It provides protection to the individual from any other respiratory tract or infection. This mask is designed by using the highest quality non-allergenic materials and advanced techniques, with the efforts and knowledge of our skilled expertise. Due to its foreign pathogens resistance, bacteria filtration efficiency, splash resistance and easy wearing, it is extremely recommended. Furthermore, we are supplying the Disposable Face Mask in several sizes and specifications at nominal rates."
    }

})

return(
    <div>
        <NavBar/>
    <div className={styless.body}>

            <div>
                <h1 style={{fontFamily:"Roboto"}}>Select your Category</h1>
                <hr/>
            </div>


            <div className={styless.sections }   >


                <div className={styless.sections1 }    >
                <Link to="/disposable_cap" className={styless.sections_link}>   <div className={styless.section_left}>
                        <img src={require("../images/productImg/disposeablecap/"+category["Disposable Cap"].img)} alt="category image" />
                    </div> </Link>
                        <div className={styless.section_right}>
                        <Link to="/disposable_cap" className={styless.name_link}><h3>Disposable Cap</h3> </Link>
                        <br/>
                        <Link  to="/disposable_cap" className={styless.view_btn_link}><button className={styless.view_btn}>View</button></Link>
                    </div>

                </div>

                <hr/>
{/* category 2 */}
                <div className={styless.sections2 }   >
                <Link to="/disposable_face_mask" className={styless.sections_link} >   <div className={styless.section_left}>
                        <img src={require("../images/productImg/face_mask/"+category["Disposable Face Mask"].img)} alt="category image" />
                    </div></Link>
                        <div className={styless.section_right}>
                        <Link to="/disposable_face_mask" className={styless.name_link}> <h3>Disposable Face Mask</h3> </Link>
                        <Link  to="/disposable_face_mask" className={styless.view_btn_link}><button className={styless.view_btn}>View</button></Link>
                    </div>

                </div>

                <hr/>
 {/* category 3*/}

                 <div className={styless.sections2 }   >
                 <Link to="/infusion_&_transfusion" className={styless.sections_link} >  <div className={styless.section_left}>
                        <img src={require('../images/productImg/Inf_and_trans/IV Cannula-water.png')} alt="category image" />
                         </div></Link>
                        <div className={styless.section_right}>
                        <Link to="/infusion_&_transfusion" className={styless.name_link} >   <h3>Infusion & Transfusion</h3> </Link>
                        <Link  to="/infusion_&_transfusion" className={styless.view_btn_link}><button className={styless.view_btn}>View</button></Link>
                    </div>

                </div>

                <hr/>
{/* category 4 */}


                
                <div className={styless.sections2 }   >
                <Link to="/gloves" className={styless.sections_link} > 
                    <div className={styless.section_left}>
                        <img src={require('../images/productImg/gloves/disposable_latex_glove.png')} alt="category image" />
                    </div>
                </Link>
                        <div className={styless.section_right}>
                        <Link to="/gloves" className={styless.name_link} >         <h3>Disposable Gloves</h3> </Link>
                        <Link  to="/gloves" className={styless.view_btn_link}><button className={styless.view_btn}>View</button></Link>
                    </div>

                </div>




             </div>


    </div>
    <Footer/>
    </div>

);

}

export default CategoryPage