import React,{useEffect} from "react"
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import style from "../pagesCss/aboutcompany.module.css"


const AboutCompany=()=>{

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <div>
        <NavBar/>
        <div className={style.body}>
            <h2 className={style.topicsHeadings}>
            Mediglow Medicare Private Limited, established in 2023 and led by Director Prince Gupta, is a trusted name in the production of medical disposable items. Their dedication to quality, timely delivery, and customer satisfaction make them a valuable partner in the healthcare industry, contributing to the well-being of patients and the effectiveness of healthcare professionals.
            </h2>
            <p>
                
Mediglow Medicare Private Limited: Delivering Quality Medical Disposable Items Since 2023
<br/>
Mediglow Medicare Private Limited is a prominent name in the healthcare industry, specializing in the production of high-quality medical disposable items, including disposable gloves, urinary bags, and more. Established in 2023, the company has rapidly gained a reputation for its commitment to delivering top-notch products and maintaining a punctual supply chain.
<br/>
<h3 className={style.topicsHeadings}>Overview:</h3>
Mediglow Medicare Private Limited is led by a visionary director, Prince, who brings a wealth of experience and dedication to the healthcare sector. Under his leadership, the company has quickly risen to prominence as a trusted source for medical disposable items.
<br/>
<h3 className={style.topicsHeadings}>Product Portfolio:</h3>
Mediglow Medicare Private Limited offers a comprehensive range of medical disposable items that meet the rigorous quality standards required by healthcare professionals and facilities. The core products in their portfolio include:

<br/>
<h3 className={style.topicsHeadings}>Quality Assurance:</h3>
<br/>
Mediglow Medicare Private Limited places a premium on the quality of its products. The company employs strict quality control measures at every stage of production, from material sourcing to manufacturing and packaging. This commitment to quality ensures that their medical disposable items consistently meet or exceed industry standards, contributing to the safety and well-being of patients and healthcare professionals alike.
<br/>
<h3 className={style.topicsHeadings}>Timely Delivery:</h3>
<br/>
One of the distinguishing factors of Mediglow Medicare is its commitment to on-time delivery. The company understands the critical nature of medical disposable items and ensures that products reach their destination as scheduled, thus preventing any disruptions in healthcare services.
<br/>
<h3 className={style.topicsHeadings}>Customer Satisfaction:</h3>
<br/>
Mediglow Medicare Private Limited is dedicated to maintaining an excellent relationship with its customers. They value feedback and continually strive to improve their products and services to better meet the evolving needs of the healthcare sector.
<br/>
<h3 className={style.topicsHeadings}>Looking to the Future:</h3>
<br/>
As Mediglow Medicare Private Limited continues to grow and evolve, it remains focused on innovation and expanding its product range to better serve the healthcare industry. The company's unwavering commitment to quality, punctuality, and customer satisfaction positions it as a reliable partner for healthcare facilities and professionals worldwide.
            </p>


        </div>

        <Footer/>
        </div>
    );

}

export default AboutCompany;