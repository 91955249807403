import { useState,React } from 'react';
import './App.css';
import { Routes,Route } from 'react-router-dom';
import HomePage from "./pages/HomePage";
import AboutCompany from "./pages/AboutCompany"
import Product from './pages/Product';
import ContactPage from './pages/ContactPage';
import CategoryPage from './pages/CategoryPage';
import InfandTrans from './pages/InfandTrans';
import Gloves from './pages/Gloves';


function App() {

  



  return (
    <div >
      <Routes>
        <Route path='' element={<HomePage></HomePage>}/>
        <Route path='/about' element={<AboutCompany></AboutCompany>}/>
        <Route path='/category' element={<CategoryPage/>}/>
        <Route path='/contact' element={<ContactPage/>}/>

        <Route path='/infusion_&_transfusion' element={<InfandTrans/>}/>

        <Route path='/gloves'  element={<Gloves/>}/>

       {/* <Route path="/support" element={<Contact></Contact>}/> */}

       <Route path='/disposable_cap' element={<Product name={"Disposable Cap"} />}/>
       <Route path='/disposable_face_mask' element={<Product name={"Face Mask"} />}/>
       
       {/* InfAnd Trans routes */}
       <Route path='/IV_flow_regulator' element={<Product name={"IV Flow Regulator"} />}/>
       <Route path='/IV_cannula' element={<Product name={"IV Cannula"} />}/>
       <Route path='/pressure_monitoring_line' element={<Product name={"Pressure Monitoring Line"} />}/>
       <Route path='/blood_transfusion_set' element={<Product name={"Blood Transfusion Set"} />}/>
       <Route path='/disposable_syringe' element={<Product name={"Disposable Syringe"} />}/>
       <Route path='/measured_volume_burette_set' element={<Product name={"Measured volume Burette Set"} />}/>
       <Route path='/medical_extension_tube' element={<Product name={"Medical Extension Tube"} />}/>
       <Route path='/IV_set' element={<Product name={"IV Set"} />}/>
       <Route path='/Infusion_sets' element={<Product name={"Infusion Sets"} />}/>
       
       {/* Gloves */}

       <Route path='/disposable_latex_glove' element={<Product name={"Disposable Latex Glove"}/>} />
       <Route path='/examination_rubber_gloves' element={<Product name={"Examination Rubber Gloves"}/>} />
       <Route path='/latex_surgical_gloves' element={<Product name={"Latex Surgical Gloves"}/>} />

      </Routes>
  
    </div>
  );
}

export default App;
