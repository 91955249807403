import React,{useState} from 'react';
import styles from '../compStyles/contact.module.css';
import axios from 'axios'
import Alert from "../components/Alert";

function Contact(){

// =========================  Form data
const [formAlert,setFormAlert]=useState(false)

const [message,setMessage]=useState(" Please Fill All Required Fields Correctly !")
const [processStage,setProcessStage]=useState("form")
const [alertColor,setAlertColor]=useState("red") 
const [formData,setFormData]=useState({
    name :"",
    email :"",
    phone_number :"",
    topic :"",
    message:""
   })

    const sendData=(event)=>{
    event.preventDefault();
    console.log(formData)
    
    setProcessStage("sending")

    if(formData.email =="" || formData.name =="" || formData.phone_number =="" || formData.topic==""){
      setMessage(" Please Fill All Required Fields Correctly !")
      setFormAlert(true)
      setProcessStage("form")
      setTimeout(()=> setFormAlert(false), 5000);
      setAlertColor("red")
    }
    else if(validateEmail(formData.email)==false ){
        setAlertColor("red")
      setMessage("Please Enter Valid Email")
      setFormAlert(true)
      setProcessStage("form")
      setTimeout(()=> setFormAlert(false), 5000);
    }
    else if(validatePhone(formData.phone_number)==false){
        setAlertColor("red")
      setMessage("Please Enter 10 Digit Phone Number")
      setFormAlert(true)
      setProcessStage("form")
      setTimeout(()=> setFormAlert(false), 5000);
    }
    else{
        setAlertColor("blue")
        setMessage("Sending Query ..")
        setFormAlert(true)
        axios.post("https://raj74434.pythonanywhere.com/medi/inquery", 
        formData
      )
    .then(response => {
        setMessage("Sent Successfully")
        setAlertColor("green")
        console.log(response)
        setFormData({
            name :"",
            email :"",
            phone_number :"",
            topic :"",
            message:""
           })
        setTimeout(()=> setFormAlert(false), 6000);
        
          

    })
    .catch(error => {
        setAlertColor("red")
      setMessage("Please Contact Us On Phone Number or given email")
      setFormAlert(true)
      setProcessStage("form")
      setTimeout(()=> setFormAlert(false), 5000);
      console.error('Error:', error);
    });
  }
     
   }


// =========================  Validate

const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    // Regular expression for basic phone number validation
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

    return (
        <div className={styles.contact}>
            <h1 style={{fontFamily:"sarif",marginBottom:"40px"}}>May I Help You?</h1>
            <Alert props={formAlert} message={message} color={alertColor} />
            <form onSubmit={sendData} className={styles.contactForm}>
                <input required  onChange={(event) => setFormData({ ...formData, name: event.target.value })}   type='text' placeholder='Name' name='name' value={formData.name} ></input>
                <br/>
                <input required onChange={(event) => setFormData({ ...formData, email: event.target.value })} type='email' placeholder='Email' name='email' value={formData.email}></input>
                <br/>
                <input required onChange={(event) => setFormData({ ...formData, phone_number: event.target.value })}  type='number' placeholder='Phone Number' name='phone_number' value={formData.phone_number}></input>
                <br/>
                <input required onChange={(event) => setFormData({ ...formData, topic: event.target.value })}  type='text' placeholder='Enter Topic' name='topic' value={formData.topic}></input>
                <br/>
                <textarea required onChange={(event) => setFormData({ ...formData, message: event.target.value })}  rows="4" cols="50" className={styles.message}  value={formData.message} placeholder="Enter your message here..."></textarea>
                <br/>
                <button type='submit' className={styles.contactbtn}> Send</button>
            </form>
        </div>
    );
}

export default Contact;
