import React,{useState,useEffect} from "react";
import style from "../pagesCss/inf_and_trans.module.css"
import NavBar from "../components/NavBar.jsx"
import Footer from "../components/Footer"
import { Link } from "react-router-dom"
import Model from "../components/ContactModel";




const InfandTrans=()=>{

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    // const i="IV-Flow-Regulator-water.png";

    const data=[
       {
            "link":"/IV_flow_regulator",
            "name":"IV Flow Regulator",
            "img":"IV-Flow-Regulator-water.png",
            "des":"We are reckoned organization deeply involved in manufacturing and exporting an exclusive range of IV Flow Regulator. It is ready to use and demanded to give some medicines slowly to the Cardiac patients, ICCU, ICU, Kidney failure and other patients. The offered regulator is made by utilizing the top grade quality raw materials and sophisticated techniques, under the instructions of our team of adroit professionals. Moreover, the IV Flow Regulator can be availed in different sizes at pocket-friendly rates."
           
        },
        {
            "link":"/IV_cannula",
            "name":"IV Cannula",
            "img":"IV Cannula-water.png",
            "des":"Being quality-centric organization, we are occupied in manufacturing and exporting an exclusive range of IV Cannula. During the treatment of patients, it is extensively used in hospitals. The offered cannula is designed with luer lock plug and an imported needle, by using the optimum quality materials and innovative techniques under the supervision of our team of highly experienced professionals. Apart from this, we are offering the IV Cannula in different sizes and specifications at very reasonable rates."
        },
        {
            "link":"/pressure_monitoring_line",
            "name":"Pressure Monitoring Line",
            "img":"Pressure Monitoring Line-water.png",
            "des":"Our organization is one of the foremost Manufacturers, Suppliers and Exporter of Pressure Monitoring Line. It monitors the fluent blood pressure accurately and used for the connection between patient and syringe infusion pump. Under the instructions of our team of highly experienced professionals, it is demanded by using the superior quality materials and ultra-modern techniques. We ensure it is suitable for C.V.P. measurement, Arteriography, Angiography, etc. Moreover, we are offering the Pressure Monitoring Line as per the requirement at market leading prices."
        },
        {
            "link":"/blood_transfusion_set",
            "name":"Blood Transfusion Set",
            "img":"Blood Transfusion SetWater.png",
            "des":"Our organization brings forth for our patrons superior quality B.T. Set (Blood Administration Set). Under the instructions of our team of well-experienced professionals and diligent professionals, it is designed by using the top-notch quality components and advanced techniques inset with the industry norms. It is easy to use, flexible chamber, kink resistance tubing and long lasting. Moreover, we are offering the  B.T. Set in different packaging options at very reasonable prices."
        },
        {
            "link":"/disposable_syringe",
            "name":"Disposable Syringe",
            "img":"Disposable Syringe-water.png",
            "des":"We are one of the renowned Manufacturers, Traders and Exporters of premium quality Disposable Syringe. It is used for injecting liquid injectable inside body. Under the instructions of team of medical and pharmaceutical expertise, it is processed by using the top grade quality medical grade polypropylene and advanced techniques in adherence to the industry standards and norms. Further, the Disposable Syringe available to us in different sizes and specifications at pocket-friendly rates."
        },
        {
            "link":"/measured_volume_burette_set",
            "name":"Measured volume Burette Set",
            "img":"Measured volume Burette Set-water.png",
            "des":"We are one of the leading Manufacturers and Exporters of Measured volume Burret set. It is demanded for heart patients or infants to give saline and used in laboratories, hospitals and other medical establishments. The offered set is made by using the semi rigid & transparent medical grade PVC material and innovative techniques, under the directions of our team of highly experienced professionals. Moreover, the Measured volume Burret set available to us in proper packaging option at affordable prices."
        },
        {
            "link":"/medical_extension_tube",
            "name":"Medical Extension Tube",
            "img":"Medical Extension Tube-water.png",
            "des":"Our firm is highly appreciated as the best Manufacturer, Exporter and Trader of Extension Tube. It is used in drip / micro irrigation and demanded to reduce the risk and danger of needle stick injuries. The offered tube is made by using the finest quality basic material and innovative techniques, under the instructions of our team of well-trained and expert professionals. Apart from this, we are supplying the Extension Tube in different shapes and sizes at pocket-friendly prices."
        },
        {
            "link":"/IV_set",
            "name":"IV Set",
            "img":"IV Set-water.png",
            "des":"Details of IV Set Designed by using high quality raw material equipment Offered at market leading prices Suitable for lean individuals, such as kids and athletes .We believe in satisfying the customer with innovations and product services .We have been awarded with an ISO 9001:2008 certificate for offering best quality infusion sets.We highly believe in transparent as well as honest dealing"
        },
        {
            "link":"/Infusion_sets",
            "name":"Infusion Sets",
            "img":"Infusion Sets-water.png",
            "des":"Our company is listed as the most famed Manufacturer, Trader and Exporter of Infusion Sets, It is fabricated by using the top grade quality components and ultra-modern techniques, under the guidance of our team of well-trained and knowledgeable professionals. Due to its less maintenance, easy operation, longer functional life and premium quality, it is extremely admired. Further, we are supplying the Infusion Sets at market leading prices."
        }
    ]

    const [alert,setAlert]=useState(false);

    const hideAlert=()=>setAlert(false)

    const [selectedProduct,setSelectedProduct]=useState("")

    return(
        <div>
            <NavBar/>
            <div className={style.body}>

            {alert && <Model hide={hideAlert} name={selectedProduct} />}
                {/* <img src={require(i)}/> */}
            
                {
                    data.map((el,ind)=>(
                    <div>
                        
                   <div key={ind} className={style.product}  >
                       
                   <div className={style.imgdiv}>   
                   <Link to={el.link}>  <img className={style.imgdivImg} src={require('../images/productImg/Inf_and_trans/'+el.img)}/> </Link>
                        </div>  

                        <div className={style.right}>
                        <Link to={el.link} className={style.rightProductNameLinkTag}><div><h2>{el["name"] } </h2></div> </Link>
                        <button className={style.rightContactBtn} onClick={()=>{setAlert(true); setSelectedProduct(el.name)}} > Contact Us</button>
                        </div>
                       
                    </div>
                    <hr/>
                    </div> 
                    ))
                }

            </div>

            <Footer/>
        </div>
    )

}

export default InfandTrans