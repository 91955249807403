import React, { useState } from "react";
import { Box, Button } from "@chakra-ui/react";
import {Link} from "react-router-dom"
import styles from '../compStyles/navbar.module.css';
import NavBarDrawerModel from "./NavBarDrawerModel";






function NavBar(){
    const [mobileMenu,setMobileMenu]= useState(false);


    return(
    <div  >
       

    <div className={styles.navBar} >
        <div className={styles.innerNavBar} >
       
        <div className={styles.navLeft}>
           <Link to="/" className={styles.navLeft}><img className={styles.navLogo} 
            src={require('../images/medi_logo.png')} alt="company logo" /> </Link> 
        </div>
        <div className={styles.navRight}>
           
            
            <div> <Link to="/category" className={styles.linkTag} > <h3>Products</h3></Link>
           
            <div className={styles.navContent}>


                 {/* category 5 */}
                <Link to="/infusion_&_transfusion" className={styles.sub_navbarlinkTag}  >
                    <div className={styles.sub_navbarlinkTagDiv}>

                    <h3>Infusion & <br/>Transfusion</h3> 
    
                     <div className={styles.productTypes}>

                        <Link to="/IV_flow_regulator" className={styles.sub_sub_navbarlinkTagProduct} >
                           <div >
                          <h3>IV Flow Regulator</h3>
                           </div>
                        </Link>
    

                        <Link to="/IV_set" className={styles.sub_sub_navbarlinkTagProduct} >
                           <div>
                          <h3>IV Set</h3>
                           </div>
                       </Link>

                        <Link to="/Infusion_sets" className={styles.sub_sub_navbarlinkTagProduct} >
                          <div>
                           <h3>Infusion Sets</h3>
                          </div>
                        </Link>

                        <Link to="/medical_extension_tube" className={styles.sub_sub_navbarlinkTagProduct} >
                           <div>
                          <h3>Medical Extension Tube</h3>     
                          </div>
                        </Link>
                        <Link to="/measured_volume_burette_set" className={styles.sub_sub_navbarlinkTagProduct} >
                            <div>
                           <h3>Measured volume Burette Set</h3>
                              </div>
                        </Link>
                        <Link to="/disposable_syringe" className={styles.sub_sub_navbarlinkTagProduct} >
                             <div>
                             <h3>Disposable Syringe</h3>
                              </div>
                        </Link>
                        <Link to="/blood_transfusion_set" className={styles.sub_sub_navbarlinkTagProduct} >
                <div>
                    <h3>Blood Transfusion Set</h3>
                </div>
                        </Link>
        

                        <Link to="/pressure_monitoring_line" className={styles.sub_sub_navbarlinkTagProduct} >
                <div>
                    <h3>Pressure Monitoring Line</h3>
                </div>
                        </Link>

                        <Link to="/IV_cannula" className={styles.sub_sub_navbarlinkTagProduct} >
                <div>
                <h3>IV Cannula</h3>
               </div>
                        </Link>

                     </div>
 
                    </div>
                </Link>



                {/* category */}


                <Link to="/disposable_cap" className={styles.sub_navbarlinkTag}  >
                    <div className={styles.sub_navbarlinkTagDiv}>
                    <h3>Disposable Cap</h3> 
                    <div className={styles.productTypes}>



                    <Link to="/disposable_cap" className={styles.sub_sub_navbarlinkTagProduct} >
                        <div>
                            <h3>Disposable Cap</h3>
                    
                        </div>
                    </Link>



                    </div>
                    </div>
                </Link>
                
                {/* category 2 */}
                {/* <Link to="/urinbags" className={styles.sub_navbarlinkTag}  >
                    <div className={styles.sub_navbarlinkTagDiv}>
                        <h3>Urology Bags</h3> 

                        <div className={styles.productTypes}>
                        <Link to="/urinbags" className={styles.sub_sub_navbarlinkTagProduct} >
                            <div>
                                <h3>Urine Collection Bags</h3>
                            </div>
                        </Link>
                        <Link to="/urinbags" className={styles.sub_sub_navbarlinkTagProduct} >
                            <div>

                            <h3>Urometer With Urine Bag</h3>
                    
                            </div>
                        </Link>
                    
                    </div>
                    </div>
                </Link> */}


                {/* category 3 */}
                <Link to="/disposable_face_mask" className={styles.sub_navbarlinkTag}  >
                    <div className={styles.sub_navbarlinkTagDiv}>
                        <h3>Face Mask</h3> 
                        <div className={styles.productTypes}>

                        <Link to="/disposable_face_mask" className={styles.sub_sub_navbarlinkTagProduct} >
                            <div>

                            <h3>Disposable Face Mask</h3>
                    
                            </div>
                        </Link>
                    
                    </div>
                    </div>
                </Link>




                {/* category 4 */}
                <Link to="/gloves" className={styles.sub_navbarlinkTag}  >
                     <div className={styles.sub_navbarlinkTagDiv}>
{/*change this*/}      <h3>Disposable Gloves</h3> 
                    
                       <div className={styles.productTypes}>

                        <Link to="/disposable_latex_glove" className={styles.sub_sub_navbarlinkTagProduct} >
                            <div>
                            <h3>Disposable Latex Glove</h3>
                            </div>
                        </Link>

                        <Link to="/examination_rubber_gloves" className={styles.sub_sub_navbarlinkTagProduct} >
                            <div>
                            <h3>Examination Rubber Gloves</h3>
                           </div>
                        </Link>
                        
                        <Link to="/latex_surgical_gloves" className={styles.sub_sub_navbarlinkTagProduct} >
                            <div>
                            <h3>Latex Surgical Gloves</h3>
                           </div>
                        </Link>


                    </div>


                     </div>
                </Link>
               
                {/* category 6 */}
                {/* <div>
                    <h3>Gastroenterology</h3>
                </div> */}
                {/* category 7 */}
                {/* <div>
                    <h3>Miscellaneous</h3>
                </div> */}
            </div>
            
            
            </div>
            <div> <Link to="/" className={styles.linkTag}  > <h3>Home</h3></Link> </div>
            <div> <Link to="/about"  className={styles.linkTag}>  <h3>About Us</h3></Link>  </div>
            <div> <Link to="/contact" className={styles.linkTag}>  <h3>Contact Us</h3> </Link> </div>
            
           
        </div>
        {/* Nav right ended */}
        </div>



        {/* ===================================================== */}

        <div className={styles.hamberger}>      
                
                {mobileMenu==false?
                // 
                 <img onClick={()=>setMobileMenu(!mobileMenu)}  src={require("../images/hamburger.png")} alg="menu icon"/>
                
                :
                //
                <img  onClick={()=>setMobileMenu(!mobileMenu)}  src={require("../images/cross.jpg")} alg="cross icon"/>
                }


        </div>


    {/* ========================================================= */}

    </div>

   <div className={styles.navdrawer}>
    <NavBarDrawerModel mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
    
    </div>

    </div>

    );

}



export default NavBar;