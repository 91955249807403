import React from 'react';
import style from "../compStyles/alert.module.css"

const Alert = ({props,message,color}) => {
  return (
    <div style={{backgroundColor:color}} className={props?style.alertTrue: style.alertFalse}>
      {/* <span>{message}</span> */}
      <h3>{message}</h3>
      
    </div>
  );
};

export default Alert;