import {React,useState,useEffect} from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import styless from "../pagesCss/product_body.module.css"
import Model from "../components/ContactModel";

const Products=({name})=>{
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const [productDetails,setProductDetails]=useState({
        "Disposable Cap":{
            "category":"Disposable Cap",
            "name":"Disposable Cap",
            "img":"disposeablecap/disposable_cap.jpg",
            "details":"By keeping in mind the hygienic work and safety of our clients, we are manufacturing and exporting a huge collection of Disposable Cap. It is widely used for securing the patient from dropping of hair or dandruff while performing the treatments and operations. Demanded in various pharmaceutical, food processing, laboratories, operation theaters, hospitals, etc. Under the supervision of our experienced professionals, it is fabricated by using the non woven fabric and contemporary machinery as per the industry norms. Furthermore, we are offering the skin-friendly Disposable Cap in various sizes at affordable prices."
        },
        "Face Mask":{
            "category":"Face Mask",
            "name":"Face Mask",
            "img":"face_mask/face_mask.png",
            "details":"To meet the diverse requirements of our leading clients, we are manufacturing and exporting a wide range of Disposable Face Mask. It provides protection to the individual from any other respiratory tract or infection. This mask is designed by using the highest quality non-allergenic materials and advanced techniques, with the efforts and knowledge of our skilled expertise. Due to its foreign pathogens resistance, bacteria filtration efficiency, splash resistance and easy wearing, it is extremely recommended. Furthermore, we are supplying the Disposable Face Mask in several sizes and specifications at nominal rates."
        }
        ,
        "IV Flow Regulator":{
            "category":"InfandTrans",
            "name":"IV Flow Regulator",
            "img":"Inf_and_trans/IV-Flow-Regulator-water.png",
            "details":"We are reckoned organization deeply involved in manufacturing and exporting an exclusive range of IV Flow Regulator. It is ready to use and demanded to give some medicines slowly to the Cardiac patients, ICCU, ICU, Kidney failure and other patients. The offered regulator is made by utilizing the top grade quality raw materials and sophisticated techniques, under the instructions of our team of adroit professionals. Moreover, the IV Flow Regulator can be availed in different sizes at pocket-friendly rates."
        },
        "IV Cannula":{
            "category":"InfandTrans",
            "name":"IV Cannula",
            "img":"Inf_and_trans/IV Cannula-water.png",
            "details":"Being quality-centric organization, we are occupied in manufacturing and exporting an exclusive range of IV Cannula. During the treatment of patients, it is extensively used in hospitals. The offered cannula is designed with luer lock plug and an imported needle, by using the optimum quality materials and innovative techniques under the supervision of our team of highly experienced professionals. Apart from this, we are offering the IV Cannula in different sizes and specifications at very reasonable rates."
        },
        "Pressure Monitoring Line":{
            "category":"InfandTrans",
            "name":"Pressure Monitoring Line",
            "img":"Inf_and_trans/Pressure Monitoring Line-water.png",
            "details":"Our organization is one of the foremost Manufacturers, Suppliers and Exporter of Pressure Monitoring Line. It monitors the fluent blood pressure accurately and used for the connection between patient and syringe infusion pump. Under the instructions of our team of highly experienced professionals, it is demanded by using the superior quality materials and ultra-modern techniques. We ensure it is suitable for C.V.P. measurement, Arteriography, Angiography, etc. Moreover, we are offering the Pressure Monitoring Line as per the requirement at market leading prices."
        },
        "Blood Transfusion Set":{
            "category":"InfandTrans",
            "name":"Blood Transfusion Set",
            "img":"Inf_and_trans/Blood Transfusion SetWater.png",
            "details":"Our organization brings forth for our patrons superior quality B.T. Set (Blood Administration Set). Under the instructions of our team of well-experienced professionals and diligent professionals, it is designed by using the top-notch quality components and advanced techniques inset with the industry norms. It is easy to use, flexible chamber, kink resistance tubing and long lasting. Moreover, we are offering the  B.T. Set in different packaging options at very reasonable prices."
        },
        "Disposable Syringe":{
            "category":"InfandTrans",
            "name":"Disposable Syringe",
            "img":"Inf_and_trans/Disposable Syringe-water.png",
            "details":"We are one of the renowned Manufacturers, Traders and Exporters of premium quality Disposable Syringe. It is used for injecting liquid injectable inside body. Under the instructions of team of medical and pharmaceutical expertise, it is processed by using the top grade quality medical grade polypropylene and advanced techniques in adherence to the industry standards and norms. Further, the Disposable Syringe available to us in different sizes and specifications at pocket-friendly rates."
        },
        "Measured volume Burette Set":{
            "category":"InfandTrans",
            "name":"Measured volume Burette Set",
            "img":"Inf_and_trans/Measured volume Burette Set-water.png",
            "details":"We are one of the leading Manufacturers and Exporters of Measured volume Burret set. It is demanded for heart patients or infants to give saline and used in laboratories, hospitals and other medical establishments. The offered set is made by using the semi rigid & transparent medical grade PVC material and innovative techniques, under the directions of our team of highly experienced professionals. Moreover, the Measured volume Burret set available to us in proper packaging option at affordable prices."
        },
        "Medical Extension Tube":{
            "category":"InfandTrans",
            "name":"Medical Extension Tube",
            "img":"Inf_and_trans/Medical Extension Tube-water.png",
            "details":"Our firm is highly appreciated as the best Manufacturer, Exporter and Trader of Extension Tube. It is used in drip / micro irrigation and demanded to reduce the risk and danger of needle stick injuries. The offered tube is made by using the finest quality basic material and innovative techniques, under the instructions of our team of well-trained and expert professionals. Apart from this, we are supplying the Extension Tube in different shapes and sizes at pocket-friendly prices."
        },
        "IV Set":{
            "category":"InfandTrans",
            "name":"IV Set",
            "img":"Inf_and_trans/IV Set-water.png",
            "details":"Details of IV Set Designed by using high quality raw material equipment Offered at market leading prices Suitable for lean individuals, such as kids and athletes .We believe in satisfying the customer with innovations and product services .We have been awarded with an ISO 9001:2008 certificate for offering best quality infusion sets.We highly believe in transparent as well as honest dealing."
        },
        "Infusion Sets":{
            "category":"InfandTrans",
            "name":"Infusion Sets",
            "img":"Inf_and_trans/Infusion Sets-water.png",
            "details":"Our company is listed as the most famed Manufacturer, Trader and Exporter of Infusion Sets, It is fabricated by using the top grade quality components and ultra-modern techniques, under the guidance of our team of well-trained and knowledgeable professionals. Due to its less maintenance, easy operation, longer functional life and premium quality, it is extremely admired. Further, we are supplying the Infusion Sets at market leading prices."
        },
// Gloves

        "Disposable Latex Glove":{
            "category":"Gloves",
            "link":"disposable_latex_glove",
            "name":"Disposable Latex Glove",
            "img":"gloves/disposable_latex_glove.png",
            "details":"Disposable Latex Glove is a flexible and soft hand protective covering that is made up by using premium quality latex materials. This single use glove is commonly used by doctors and surgeons to prevent risk of disease transmission. It is in high demand within healthcare facilities, food service, and laboratory settings, among others. Disposable Latex Glove is highly elastic, which allows them to fit snugly and comfortably on the hand. It comes in various standard sizes as per the order placed by our customers. Buy from us latex gloves in bulk at a reasonable price."
           
        },
        "Examination Rubber Gloves":{
            "category":"Gloves",
            "link":"examination_rubber_gloves",
            "name":"Examination Rubber Gloves",
            "img":"gloves/examination_rubber_gloves.png",
            "details":" This Examination Rubber Glove is designed with several features to provide maximum protection and comfort. It is made of high-quality, medical-grade materials that offer excellent strength and durability. It comes with a textured surface that provides an enhanced grip for secure handling. It is suitable for use by people with sensitive skin. This Examination Rubber Glove ensures a perfect fit for different hand sizes. It is also easy to wear, with a flexible design that offers excellent tactile sensitivity and dexterity, and it is ideal for delicate medical procedures."
        },
        "Latex Surgical Gloves":{
            "category":"Gloves",
            "link":"latex_surgical_gloves",
            "name":"Latex Surgical Gloves",
            "img":"gloves/latex_surgical_gloves.png",
            "details":"Our company is highly appreciated as the best Trader, Exporter and Manufacturer of Latex Surgical Gloves. It is ideal for general surgery and widely used by medical professionals & doctors to avoid any kind of infection. These gloves are fabricated under the instructions of our team of well-experienced professionals, by utilizing the superior grade leather and modern techniques in compliance with the global market standards and norms. Moreover, we are supplying the Latex Surgical Gloves in different sizes at affordable prices."
        }


    })

    const [alert,setAlert]=useState(false);
    const hideAlert=()=>setAlert(false)
    const [selectedProduct,setSelectedProduct]=useState("")


    const pro=productDetails[name]

    return(
        <div>
        <NavBar/>

        <div className={styless.below_nav_body} >

        {alert && <Model hide={hideAlert} name={selectedProduct} />}
 
            <div className={styless.section1}>

                    <div className={styless.section1_left} >
                    {/* <h3>Category {pro.name} </h3> */}
                   {<img src={require('../images/productImg/'+pro.img)}/> }
                    </div>

                    <div className={styless.section1_right} >
                        <div><h2>{pro.name}</h2></div>
                        
                        <hr/>

                        <div><button className={styless.productDetailsBTN} onClick={()=>{setAlert(true); setSelectedProduct(pro.name)}}><h3>Get Price / Catalogue</h3></button></div>
                        <div><h4>Product details :</h4><p>Given below</p></div>

                        <hr/>

                        <button className={styless.productDetailsBTN} onClick={()=>{setAlert(true); setSelectedProduct(pro.name)}} ><h3>Click For Inquiry </h3></button>
                        <br/>
                        <button className={styless.productDetailsBTN} onClick={()=>{setAlert(true); setSelectedProduct(pro.name)}} ><h3>Click For Call Back</h3></button>
                       
                    </div>

           
            </div>


             {/* section 2 started */}

             <div className={styless.section2}>
                <hr/>
                <h2>About Product</h2>

                <h3>{pro.details}</h3>


             </div>
                
        {/* section 3 started */}

        <div>
            

        </div>
            


            {/* body ended */}
        </div>

        <Footer/>
        </div>
    );


}

export default Products;