import React,{useState,useEffect} from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import FAQ from "../components/FAQ";
import Contact from "../components/Contact";
import styless from "../pagesCss/belownav.module.css"
import Alert from "../components/Alert";
import axios from 'axios'
import styles from "../pagesCss/contactus.module.css"
import {Link} from "react-router-dom"


const ContactPage=()=>{

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);





     // =========================  Form data
     const [formAlert,setFormAlert]=useState(false)

     const [message,setMessage]=useState(" Please Fill All Required Fields Correctly !")
     const [processStage,setProcessStage]=useState("form")
     const [alertColor,setAlertColor]=useState("red") 
     const [formData,setFormData]=useState({
         name :"",
         email :"",
         phone_number :"",
         topic :"",
         message:""
        })
   
         const sendData=(event)=>{
         event.preventDefault();
         console.log(formData)
         
         setProcessStage("sending")
   
         if(formData.email =="" || formData.name =="" || formData.phone_number =="" || formData.topic==""){
           setMessage("Please Fill All Requird Feilds Correctly !")
           setFormAlert(true)
           setProcessStage("form")
           setTimeout(()=> setFormAlert(false), 5000);
           setAlertColor("red")
         }
         else if(validateEmail(formData.email)==false ){
             setAlertColor("red")
           setMessage("Please Enter Valid Email")
           setFormAlert(true)
           setProcessStage("form")
           setTimeout(()=> setFormAlert(false), 5000);
         }
         else if(validatePhone(formData.phone_number)==false){
             setAlertColor("red")
           setMessage("Please Enter 10 Digit Phone Number")
           setFormAlert(true)
           setProcessStage("form")
           setTimeout(()=> setFormAlert(false), 5000);
         }
         else{
             setAlertColor("blue")
             setMessage("Sending Query ..")
             setFormAlert(true)
             axios.post("https://raj74434.pythonanywhere.com/medi/inquery", 
             formData
           )
         .then(response => {
             setMessage("Sent Successfully")
             setAlertColor("green")
             console.log(response)
             setFormData({
                 name :"",
                 email :"",
                 phone_number :"",
                 topic :"",
                 message:""
                })
             setTimeout(()=> setFormAlert(false), 6000);
             
               
 
         })
         .catch(error => {
             setAlertColor("red")
           setMessage("Please Contact Us On Phone Number or given email")
           setFormAlert(true)
           setProcessStage("form")
           setTimeout(()=> setFormAlert(false), 5000);
           console.error('Error:', error);
         });
       }
          
        }
 
 
     // =========================  Validate
 
     const validateEmail = (email) => {
         // Regular expression for basic email validation
         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
         return emailRegex.test(email);
       };
     
       const validatePhone = (phone) => {
         // Regular expression for basic phone number validation
         const phoneRegex = /^\d{10}$/;
         return phoneRegex.test(phone);
       };

    return(
        <div>
            <NavBar/>
            <Alert props={formAlert} message={message} color={alertColor} />
            <div className={styles.pageContent} >
              
              <div className={styles.image_with_contactInfo}>
              
              <div className={styles.contactInfo}>
                <div><h1 className={styles.contactInfoheading}>Let us know your Requirements  </h1>
               
                </div>
                <div className={styles.contactContentDiv}>
                  
                  <div className={styles.contactLine}>
                  <div className={styles.contactLineSVG}> <p>
                        <svg  xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                         <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                         </svg>
                         </p>
                  </div>
                  <div>
                        +91- <Link className={styles.contactLinkTag}  to={"tel:9667065001"}>
                             9667065001 </Link>

                  </div>
                  </div>
                  
                    <div className={styles.contactLine}>
                  <div className={styles.contactLineSVG}>
                    <p>  
                      <svg  xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                      </svg> 
                      </p>
                  </div>
                  <div>
                    <p> 
                      <Link className={styles.contactLinkTag} to={"mailto:mediglowmedicare.com"}>                                     
                      info@mediglowmedicare.com </Link>
                    </p>
                  </div>

                  </div>

                  <div className={styles.contactLine}>
                  <div className={styles.contactLineSVG}> <svg xmlns="http://www.w3.org/2000/svg"  width="100%" height="100%"  fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                     <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                     </svg> 
                  </div>
                   <div >
                    <p> 
                      <Link className={styles.contactLinkTag} to={"mailto:mediglowmedicare.com"}> 
                         prince@mediglowmedicare.com 
                     </Link>
                    </p>
                   </div>
                  
                  </div>
                
                </div>
              </div>

              <div className={styles.contactImgDiv}>
                <img className={styles.contactImg} src={require("../images/contactus/contactus.jpg")}/>
                
              </div>

              </div>



              <div className={styles.contactFormSection}>
                
                

                <div className={styles.contactFormSectionRight}>
                <Contact/>
                </div>
                <div className={styles.contactFormSectionLeft}>
                  <div>
                    <h1> Our Location</h1>
                    <p>2443, Sector 7A
                      <br/>
                     Faridabad, Haryana 121006</p>
                  </div>

                     <div>
                        <Link   to={"/category"}><button className={styles.categoryBtn}>See Our Products</button></Link>
                     </div>

                </div>
              </div>

              
                
                {/* <FAQ/> */}
            </div>
           
            <Footer/>
           

        </div>
    );

}

export default ContactPage