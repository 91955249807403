import React, { useState } from 'react'
import styles from '../compStyles/contactmodels.module.css'
import axios from 'axios'
import Alert from './Alert'




function Model(props) {

  const [message,setMessage]=useState(" Please Fill All Required Fields Correctly !")

    const [formAlert,setFormAlert]=useState(false)

     const [processStage,setProcessStage]=useState("form")

     const [formData,setFormData]=useState({
      name :"",
      email :"",
      phone_number :"",
      topic :props.name ,
      message:""
     })

     const sendData=(event)=>{
      console.log(formData)
      
      setProcessStage("sending")

      if(formData.email =="" || formData.name =="" || formData.phone_number =="" || formData.topic=="" || formData.message==""){
        setMessage(" Please Fill All Required Fields Correctly !")
        setFormAlert(true)
        setProcessStage("form")
        setTimeout(()=> setFormAlert(false), 5000);
      }
      else if(validateEmail(formData.email)==false ){
        setMessage("Please Enter Valid Email")
        setFormAlert(true)
        setProcessStage("form")
        setTimeout(()=> setFormAlert(false), 5000);
      }
      else if(validatePhone(formData.phone_number)==false){
        setMessage("Please Enter 10 Digit Phone Number")
        setFormAlert(true)
        setProcessStage("form")
        setTimeout(()=> setFormAlert(false), 5000);
      }
      else{
        axios.post("https://raj74434.pythonanywhere.com/medi/inquery", 
        formData
        )
      .then(response => {
   
      setProcessStage("sent")

      })
      .catch(error => {
        setMessage("Please Contact Us On Phone Number or given Email")
        setFormAlert(true)
        setProcessStage("form")
        setTimeout(()=> setFormAlert(false), 5000);
      });
    }
      
      
     }

     

    //  Validating Data============================================

    const validateEmail = (email) => {
      // Regular expression for basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
  
    const validatePhone = (phone) => {
      // Regular expression for basic phone number validation
      const phoneRegex = /^\d{10}$/;
      return phoneRegex.test(phone);
    };




  return (
    
    <>
      <div className={styles.back} onClick={()=>props.hide()}></div>
       <Alert props={formAlert} message={message} />
    <div className={styles.model}>

    { processStage=="sent"?
      <>
      <h1 >Thank You For Contacting Us !</h1>
    <div class={styles.wrapper}>
       <svg class={styles.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
    </svg>
   </div>
   <button className={styles.ThankYouOkBtn} onClick={()=>props.hide()}> OK </button>
   </>

   :
   <>
      <div><h2> Get In Touch!</h2></div>
      <div className={styles.modelForm}>
        <input className={styles.modelFormName} required onChange={(event)=>formData.name=event.target.value} type='text' name='name' placeholder='Enter Name' />
        <br/>
        <input required onChange={(event)=>formData.email=event.target.value} type='text' name='email' placeholder='Enter Email' />
        <br/>
        
        <input required onChange={(event)=>formData.phone_number=event.target.value} type='number' name='phone' placeholder="Phone Number (10 Digit )" />
        <br/>
        { 
         props.name == ""?
          <>
         <input placeholder='Enter Topic' onChange={(event)=>formData.topic=event.target.value}></input>
          </>
          :
          <div className={styles.inqueryProductDiv} >
           <p className={styles.inquery} >Inquery For :  </p>
          <h2 className={styles.inqueryProduct} >{props.name}</h2>
          </div> 
        }
        
        
        <textarea required className={styles.modelMsg} onChange={(event)=>formData.message=event.target.value} name='details' placeholder="Product Quantity Or Other Details" />
      </div>
      
      { processStage=="form"?
      <>
      <button className={styles.popupBtn} onClick={(event)=>sendData(event)}>Send</button>
      <button className={styles.cancelBtn} onClick={()=>props.hide()}>Cancel</button> 
      </>
      :
      <> <div class={styles.loader}></div>  
      </>
      }
      </>

    }
    </div>
    </>
    
   
    
  )
}

export default Model